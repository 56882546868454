.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App > * {
  flex: 1;
}
